.iframe-container {
    /* Styles for the container */
}

.iframe-card {
    height: 100vh;
    /* Additional styles for the card */
}

.iframe-content {
    width: 100%;
    height: 100vh;
    /* Styles for the iframe */
}

.exam-controls {
    display: flex;
    gap: 10px;
    padding: 10px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    border-top: 1px solid #ddd;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.timer-card {
    background-color: #f8f9fa;
    padding: 2px 2.5px;
    border-radius: 20px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
    margin-top: 2px;
    /* To add some space at the top */
}

.timer-icon {
    color: #007bff;
    /* Bootstrap primary color */
    margin-right: 8px;
}

.timer-text {
    font-size: 1.2rem;
    color: #333;
}