.footer {
    /* padding: 20px 0; */
    background-color: #f8f9fa;
    border-top: 1px solid #dee2e6;
    color: #212529;
    font-size: 0.35rem;
}

.footer .footer-text {
    margin: 0;
    font-size: 1.11rem !important;
    color: #6c757d;
}

.footer a {
    color: #007bff;
    text-decoration: none;
}

.footer a:hover {
    text-decoration: underline;
}

.social-icons {
    font-size: 1.5rem;
    color: #333;
}

.social-icons .fa {
    margin-right: 10px;
    cursor: pointer;
}

.social-icons .fa:hover {
    color: #0056b3;
}