.not-found-container {
    padding-top: 5rem;
    padding-bottom: 5rem;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.not-found-icon {
    color: #004085;
    /* Bootstrap primary color */
    margin-bottom: 1rem;
}

h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
}

h2 {
    font-size: 2rem;
    color: #6c757d;
    /* Bootstrap secondary text color */
    margin-bottom: 2rem;
}

p {
    font-size: 1.25rem;
    margin-bottom: 3rem;
}

@media (max-width: 768px) {
    .not-found-container {
        padding-top: 3rem;
        padding-bottom: 3rem;
    }

    h1 {
        font-size: 2.5rem;
    }

    h2 {
        font-size: 1.75rem;
    }

    p {
        font-size: 1rem;
    }
}