.phone-input-container {
    position: relative;
    margin-bottom: 1rem;
}

.custom-phone-input {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ced4da;
    font-size: 16px;
    color: #495057;
}

.custom-phone-input:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

/* Style the input field */
.custom-phone-input .PhoneInputInput {
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    padding: 0.375rem 0.75rem;
}

/* Style the country select dropdown */
.custom-phone-input .PhoneInputCountrySelect {
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    padding: 0.375rem 0.75rem;
}

/* Style the placeholder text */
.custom-phone-input .PhoneInputInput::placeholder {
    color: #6c757d;
}

/* Style the flags */
.custom-phone-input .PhoneInputCountryIcon {
    border-radius: 0.25rem;
}

/* Responsive adjustments */
@media (max-width: 768px) {

    .custom-phone-input .PhoneInputInput,
    .custom-phone-input .PhoneInputCountrySelect {
        padding: 0.5rem;
    }
}