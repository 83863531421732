/* Custom CSS */
.card-body-collapsible {
    overflow: hidden;
    transition: max-height 0.1s ease;
}

/* Tests */
.fade-animation {
    transition: opacity 0.5s ease;
}

.hidden {
    opacity: 0;
}

.visible {
    opacity: 1;
}