/* PasswordReset.css */

/* Example styles - customize as needed */
.resetPassword {
    max-width: 600px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: #fff;
}

h2 {
    color: #333;
}

button {
    width: 100%;
    padding: 10px;
    font-size: 16px;
}

.passFgt-background {
    background: linear-gradient(135deg, #5f6061 0%, #83b4b6 100%);
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

/* Add more styles as needed */