/* .mt-4 {
    margin-top: 4rem;
}

.text-center {
    text-align: center;
}

.m-4 {
    margin: 2rem;
}

*/
.form-control {
    margin-bottom: 1rem;
}

.w-100 {
    width: 100%;
}

.spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: 0.25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    animation: spinner-border .75s linear infinite;
}

/* Alert customization */
.alert-info {
    color: #0c5460;
    background-color: #d1ecf1;
    border-color: #bee5eb;
}

/* Ensuring text is centered and has some margin on top */
.text-center.mt-3 {
    /* margin-top: 1rem;text-align: center; */
}

.payment-card {
    max-width: 600px;
    margin: auto;
    background: #fff;
    box-shadow: 0 0.5rem 1.5rem rgb(2, 104, 99);
    border-radius: 0.5rem;
    border: none;
}

.payment-card-header {
    background-color: #854a12;
    background-color: #024245;
    /* Airtel blue */
    color: white;
    text-align: center;
    padding: 1rem;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
}

.payment-card-header h3 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

.payment-card-header p {
    font-size: 1rem;
}

.payment-submit-btn {
    width: 100%;
    padding: 0.75rem;
    font-size: 1.1rem;
    background-color: #525f6d;
    /* Airtel blue */
    border-color: #007bff;
}

.payment-message {
    margin-top: 1rem;
    text-align: center;
    color: #6c757d;
}