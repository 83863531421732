.receipt-container {
    background: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.receipt-header h1 {
    color: #333;
}

.receipt-body {
    background: #fff;
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 20px;
}

.receipt-info h2,
.receipt-address h2,
.receipt-item-details h2 {
    color: #007bff;
    margin-bottom: 15px;
}

.receipt-info p,
.receipt-address p,
.receipt-item-details p,
.receipt-payment-info p {
    font-size: 16px;
    line-height: 1.5;
    color: #555;
}

.receipt-footer {
    text-align: right;
}

/* Adjustments for button styling */
.btn-primary,
.btn-dark {
    margin: 5px;
}