/* .mt-5 {
    margin-top: 3rem;
} */

.shadow {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    /* Bootstrap default shadow */
}

/* Phone input customization */
.form-control {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
}

/* Payment button customization */
.btn-primary {
    /* margin-top: 1rem; */
    padding: 0.75rem;
    font-size: 1.1rem;
    /* font-weight: bold; */
}

/* Success icon in green */
.text-success {
    color: #28a745;
    /* Bootstrap success color */
}

/* Centered button below the card */
.text-center {
    /* margin-top: 1rem;display: flex;
justify-content: center; */
}

/* Spacing for the receipt button */
.btn-success {
    /* margin-top: 1rem; */
}

.payment-submit-btn {
    background-color: #f58220;
    /* MTN color */
    border-color: #f58220;
}