/* CheckboxQuestion.css */
.custom-checkbox .form-check-input {
    opacity: 0;
    /* Hide the default checkbox */
    position: absolute;
}

.custom-checkbox .form-check-label {
    position: relative;
    padding-left: 2rem;
    /* Space for custom checkbox */
    cursor: pointer;
}

.custom-checkbox .form-check-label::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 20px;
    width: 20px;
    background-color: #fff;
    border: 2px solid rgb(60, 0, 170);
    border-radius: 4px;
    /* Slight radius for a modern look */
    box-sizing: border-box;
}

.custom-checkbox .form-check-input:checked+.form-check-label::after {
    content: "";
    position: absolute;
    left: 5px;
    top: 50%;
    transform: translateY(-50%);
    height: 10px;
    width: 10px;
    background-color: rgb(3, 48, 232);
    border-radius: 2px;
    /* Slight radius to match the box */
}