.App {
    background-color: hsl(236, 72%,
79%),
hsl(237, 63%, 64%);
min-height: 100vh;
display: flex;
flex-direction: column;
}

.main-content {
    flex: 1;
    margin-top: 0.009rem;
}


@media (max-width: 768px) {
    .main-content {
        padding-top: 100px;
    }
}