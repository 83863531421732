.quiz-container {
    padding: 20px;
    margin: 20px;
    background: rgb(224, 224, 224);
}

.question {
    position: relative;
    margin-top: 15px;
    margin-bottom: 15px;
    padding: 10px;
    border: 1px solid #696969;
    border-radius: 5px;
}

.instruction {
    font-weight: bold;
    font-size: 18px;
}

/* input[type="text"] {
    background-color: #f0f0f000;
    border: 1px solid #000000;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-radius: 0px;
    padding: 10px;
    width: 50px;
    height: 20px;
} */


.draggable-sentence {
    cursor: pointer;
}

.draggable-sentence,
.dropped-sentence,
.sentenceInOrder {
    margin: 10px 0;
    background-color: #e0e0e0;
    padding: 5px;
    border-radius: 5px;
}

.options {
    display: block;
    margin-top: 10px;
}

.options label {
    display: block;
    margin-bottom: 5px;
    margin-left: 12px;
}

.options input[type="radio"] {
    margin-right: 10px;
    margin-left: 12px;
}

.correct {
    color: green;
}

.incorrect {
    color: red;
}

#drag-container,
#drop-container,
#result-container {
    border: 2px solid #ccc;
    padding: 10px;
    margin: 10px;
    min-height: 500px;
}

.tick,
.cross {
    font-size: 25px;
    margin-left: 10px;
}

.tick {
    color: green;
}

.cross {
    color: red;
}

.drop-placeholder {
    color: red;
}

.hidden {
    display: none;
}

.correct-answer {
    position: relative;
    border-radius: 30px;
    margin-left: 25px;
    display: block;
    font-style: italic;
    text-align: left;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    color: rgb(0, 165, 41);
}

.hide-questions .hidden {
    display: none;
}

.hidden {
    display: none;
}